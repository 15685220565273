import React, { useState, Fragment, useEffect } from 'react';
import { useNotify, BooleanInput } from "react-admin";
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import { makeStyles, Radio, RadioGroup, TextField, FormControl, FormControlLabel, FormLabel, Switch } from "@material-ui/core";
import config from '../../../config/config';
import CustomButton from '../../shared/CustomButton';

const styles = {
    pricingContainer: {
        marginTop: 20, marginBottom: 30, width: '100%', display: 'flex', flexDirection: 'column',
        '& #oneTime-helper-text, & #startPriceAmount-helper-text, & #startPriceDuration-helper-text, & #mainPriceAmount-helper-text, & #mainPriceDuration-helper-text': { width: 200 }
    },
    input: { margin: 0, padding: 12, width: 55, height: 45, verticalAlign: 'middle', '& #oneTime-helper-text': { width: 200 } },
    text: { fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto, -apple-system, "Segoe UI", Arial, sans-serif', color: '#212529' },
    intervalRow: { height: 35, marginTop: 5 },
    toolbar: { display: 'flex', justifyContent: 'flex-end', marginTop: 70 }
};

const useStyles = makeStyles(styles);

const LocationsEditPricingTab = ({ readOnly }) => {
    const history = useHistory();
    const classes = useStyles();

    const formState = useFormState();
    const formData = formState.values;
    const locationId = formData.id;
    const { locationPricing } = formData;

    const [oneTimeError, setOneTimeError] = useState(false);
    const [mainPriceAmountError, setMainPriceAmountError] = useState(false);
    const [mainPriceDurationError, setMainPriceDurationError] = useState(false);
    const [startPriceAmountError, setStartPriceAmountError] = useState(false);
    const [startPriceDurationError, setStartPriceDurationError] = useState(false);

    const [state, setState] = useState({})
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!locationPricing) {
            return;
        }
        if ((locationPricing.startPriceAmount === 0 && locationPricing.mainPriceAmount === 0)) {
            setValue('free');
            setState({
                allowNoVerifiedPayment: locationPricing.allowNoVerifiedPayment,
                startPriceAmount: locationPricing.startPriceAmount || 0,
                mainPriceAmount: locationPricing.mainPriceAmount || 0
            });
        } else if (locationPricing.startPriceAmount > 0 && locationPricing.mainPriceAmount === 0) {
            setValue('oneTime');
            setState({
                allowNoVerifiedPayment: locationPricing.allowNoVerifiedPayment,
                startPriceAmount: locationPricing.startPriceAmount,
                mainPriceAmount: locationPricing.mainPriceAmount
            });
        } else if (locationPricing.mainPriceAmount > 0) {
            setValue('interval');
            setState({
                allowNoVerifiedPayment: locationPricing.allowNoVerifiedPayment,
                startPriceAmount: locationPricing.startPriceAmount,
                startPriceDuration: locationPricing.startPriceDuration,
                mainPriceAmount: locationPricing.mainPriceAmount,
                mainPriceDuration: locationPricing.mainPriceDuration,
                maxDailyAmount: locationPricing.maxDailyAmount
            });
        } else {
            console.log("No pricing defined.");
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e, value) => {
        setValue(value);
        setOneTimeError(false)

        if (value === 'free') {
            setState({
                allowNoVerifiedPayment: state.allowNoVerifiedPayment,
                startPriceAmount: 0,
                mainPriceAmount: 0,
                maxDailyAmount: 0
            });

            return;
        }

        setState({
            allowNoVerifiedPayment: state.allowNoVerifiedPayment,
            startPriceAmount: '',
            mainPriceAmount: '',
            startPriceDuration: '',
            mainPriceDuration: '',
            maxDailyAmount: '0'
        });
    };

    const CustomSaveBtn = props => {
        const notify = useNotify();

        const save = () => {
            if (value === 'oneTime') {
                if (!state.startPriceAmount || state.startPriceAmount < 1) {
                    setOneTimeError(true);
                    return;
                }
            }

            if (value === 'interval') {
                let hasError = false;
                if (state.startPriceAmount === "" || parseInt(state.startPriceAmount) < 0) {
                    setStartPriceAmountError(true);
                    hasError = true;
                }
                if (!state.mainPriceAmount || state.mainPriceAmount < 1) {
                    setMainPriceAmountError(true);
                    hasError = true;
                }
                if (!state.mainPriceDuration || state.mainPriceDuration < 1 || state.mainPriceDuration > 24) {
                    setMainPriceDurationError(true);
                    hasError = true;
                }
                if (!state.startPriceDuration || state.startPriceDuration < 1 || state.startPriceDuration > 24) {
                    setStartPriceDurationError(true);
                    hasError = true;
                }

                if (hasError) return;
            }

            const token = localStorage.getItem('access_token');
            let locationId = props.id;
            const data = Object.assign({}, { locationId, locationPaymentType: 1 }, props);
            delete data.id;
            const endpoint = `${config.apiBaseUrl}/Location/SaveLocationPrice`;
            const request = new Request(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...data })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        notify('Saved successfully');
                    } else {
                        notify(`Error: ${response.error.message}`, 'warning');
                    }
                })
                .catch(error => {
                    notify(`Error: ${error.message}`, 'warning');
                });
        }

        return <CustomButton type="save" label="Save" onClick={save} disabled={props.isDisabled} />;
    };

    const onCancel = e => {
        e.preventDefault();
        history.push('/locations');
    };
    console.log(state)
    return (
        <div className={classes.pricingContainer}>
            <div className={classes.intervalRow} style={{ marginBottom: 30 }}>
                <FormControlLabel
                    control={<Switch color="primary" />}
                    label="Allow not verified users book locker"
                    classes={{ label: classes.label, checked: classes.checkedLabel }}
                    labelPlacement="left"
                    checked={state.allowNoVerifiedPayment? true: false}
                    onChange={() => {
                        setState({ ...state, allowNoVerifiedPayment: !state.allowNoVerifiedPayment });
                    }}
                />
            </div>
            <FormControl component="fieldset" disabled={readOnly}>
                <RadioGroup
                    value={value}
                    onChange={handleChange}
                    color="secondary"
                >
                    <FormControlLabel value="free" control={<Radio color="primary" />} label="Free" />
                    <FormControlLabel value="interval" control={<Radio color="primary" />} label="Per interval" />
                    <FormControlLabel value="oneTime" control={<Radio color="primary" />} label="One time" />
                </RadioGroup>
            </FormControl>

            {value === 'interval' &&
                <Fragment>
                    <div className={classes.intervalRow}>
                        <TextField
                            id="startPriceAmount"
                            type="number"
                            inputProps={{ min: 0 }}
                            variant="standard"
                            className={classes.input}
                            value={state.startPriceAmount}
                            onChange={(e) => {
                                setState({ ...state, startPriceAmount: e.target.value });
                                setStartPriceAmountError(false);
                            }}
                            error={startPriceAmountError}
                            helperText={startPriceAmountError ? 'Incorrect input' : ''}
                            disabled={readOnly}
                        />
                        <span className={classes.text}>{config.currency} for the first </span>
                        <TextField
                            id="startPriceDuration"
                            type="number"
                            inputProps={{ min: 1, max: 24 }}
                            variant="standard"
                            className={classes.input}
                            value={state.startPriceDuration}
                            onChange={(e) => {
                                setState({ ...state, startPriceDuration: e.target.value });
                                setStartPriceDurationError(false);
                            }}
                            error={startPriceDurationError}
                            helperText={startPriceDurationError ? 'Incorrect input' : ''}
                            disabled={readOnly}
                        />
                        <span className={classes.text}> hours.</span>
                    </div>
                    <div className={classes.intervalRow}>
                        <span className={classes.text} style={{ paddingLeft: 12 }}>Then </span>
                        <TextField
                            id="mainPriceAmount"
                            type="number"
                            inputProps={{ min: "1", max: "12" }}
                            variant="standard"
                            className={classes.input}
                            value={state.mainPriceAmount}
                            onChange={(e) => {
                                setState({ ...state, mainPriceAmount: e.target.value });
                                setMainPriceAmountError(false);
                            }}
                            error={mainPriceAmountError}
                            helperText={mainPriceAmountError ? 'Incorrect input' : ''}
                            disabled={readOnly}
                        />
                        <span className={classes.text}>{config.currency} for every next </span>
                        <TextField
                            id="mainPriceDuration"
                            type="number"
                            inputProps={{ min: 1, max: 24 }}
                            variant="standard"
                            className={classes.input}
                            value={state.mainPriceDuration}
                            onChange={(e) => {
                                setState({ ...state, mainPriceDuration: e.target.value });
                                setMainPriceDurationError(false);
                            }}
                            error={mainPriceDurationError}
                            helperText={mainPriceDurationError ? 'Incorrect input' : ''}
                            disabled={readOnly}
                        />
                        <span className={classes.text}> hours.</span>
                    </div>
                    <div className={classes.intervalRow}>
                        <span className={classes.text}>Max amount per day </span>
                        <TextField
                            id="maxDailyAmount"
                            type="number"
                            inputProps={{ min: 0 }}
                            variant="standard"
                            className={classes.input}
                            value={state.maxDailyAmount}
                            onChange={(e) => {
                                setState({ ...state, maxDailyAmount: e.target.value });
                                setStartPriceDurationError(false);
                            }}
                            error={startPriceDurationError}
                            helperText={startPriceDurationError ? 'Incorrect input' : ''}
                            disabled={readOnly}
                        />
                        <span className={classes.text}>{config.currency}</span>
                    </div>
                </Fragment>
            }

            {value === 'oneTime' &&
                <div style={{ marginTop: 25, height: 90 }}>
                    <TextField
                        id="oneTime"
                        type="number"
                        inputProps={{ min: 1 }}
                        variant="standard"
                        className={classes.input}
                        onChange={(e) => {
                            setState({ startPriceAmount: e.target.value, mainPriceAmount: 0 });
                            setOneTimeError(false);
                        }}
                        error={oneTimeError}
                        helperText={oneTimeError ? 'Incorrect input' : ''}
                        value={state.startPriceAmount}
                        disabled={readOnly}
                    />
                    <span className={classes.text}>{config.currency}</span>
                </div>
            }

            <div className={classes.toolbar}>
                <CustomButton
                    label={readOnly ? 'Go Back' : 'Cancel'}
                    type="default"
                    style={{ marginRight: 20 }}
                    onClick={onCancel}
                />
                {!readOnly && (
                    <CustomSaveBtn {...state} id={locationId} isDisabled={value === ''} />
                )}
            </div>
        </div>
    );
};

export default LocationsEditPricingTab;