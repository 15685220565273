import React, { Fragment } from 'react';
import { SimpleForm, SaveButton, useNotify, refreshView } from "react-admin";

import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import CreateAdministratorDetails from './CreateAdministratorDetails';
import UserCreatePasswordTab from '../../../../users/user-create-tabs/UserCreatePasswordTab';

import { makeStyles } from '@material-ui/core';
import { darkTheme, lightTheme } from "../../../../layout/themes";

import CloseIcon from '@material-ui/icons/Close';
import CustomButton from '../../../../shared/CustomButton';

import config from '../../../../../config/config';

const CreateAdministratorDialog = ({ refreshGrid, ...props }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const { clientId } = props;

    const onCancel = () => {
        refreshGrid();
    };

    return (
        <Fragment>
            <CloseIcon style={{ position: 'absolute', top: 15, right: 15, color: theme.secondary.main, cursor: 'pointer' }} onClick={onCancel} />
            <SimpleForm toolbar={<CustomSaveButton callback={refreshGrid} clientId={clientId} />}>
                <CreateAdministratorDetails />
                <UserCreatePasswordTab isDialogForm={true} />
            </SimpleForm>
        </Fragment>
    );
};

const CustomSaveButton = ({ clientId, callback }) => {
    const styles = {
        createToolbar: { display: 'flex', justifyContent: 'flex-end', '& button': {
                margin: '0 0 0 20px !important'
            }
        }
    };
    const useStyles = makeStyles(styles);

    const dispatch = useDispatch();
    const notify = useNotify();
    const classes = useStyles();

    const form = useForm();
    const formState = useFormState();
    const { valid } = formState;

    const createClientAdmin = () => {
        if (!valid) {
            return form.submit();
        }

        let params = {...formState.values};
        params.user.clientId = clientId;
        params.user.languageId = 1;
        params.user.accessLevel = 2;

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/User/CreateOrUpdateUser`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(params)
        });

        fetch(request)
        .then(res => res.json())
        .then(response => {
            if (response.success) {
                dispatch(refreshView());
                notify('User successfully created', 'success');
    
                if (callback) {
                    callback();
                }
            }

            if (response && response.error) {
                if (response.error.message === '[Identity.Duplicate email]') {
                    return notify('Email address is already being used', 'warning');
                }

                notify(response.error.message, 'warning');
            }
        })
        .catch(error => {
            notify(`Error: ${error.message}`, 'warning');
        });
    }

    const onCancel = (e) => {
        e.preventDefault();
        callback();
    };

    return (
        <div className={classes.createToolbar}>
            <CustomButton type="default" label="Cancel" onClick={onCancel} />
            <SaveButton handleSubmitWithRedirect={createClientAdmin} />
        </div>
    );
};

export default CreateAdministratorDialog;