import React, { Fragment, useState } from 'react';
import { useQuery, useNotify, Pagination, DeleteWithConfirmButton } from 'react-admin';

import CustomLabel from '../../../../shared/CustomLabel';
import CustomButton from '../../../../shared/CustomButton';
import CreateAdministratorDialog from './CreateAdministratorDialog';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import EditIcon from '@material-ui/icons/Edit';
import { Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core/';

import { darkTheme, lightTheme } from "../../../../layout/themes";

const DeleteConfirmationDialog = ({ record = {}, clientId }) => {
    const { name, surname } = record;
    const title = `Delete administrator "${name} ${surname}"`;

    return (
        <DeleteWithConfirmButton
            record={record}
            resource="users"
            confirmTitle={title}
            redirect={`/clients/${clientId}/administrators`}
        />
    );
};

const ClientsUsersTab = ({ record }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const styles = {
        deleteButton: { cursor: 'pointer', color: theme.errorRed, fontSize: '20px' },
        editButton: { cursor: 'pointer', color: theme.primary.main, fontSize: '20px' },
        dialogPaper: { minWidth: '40%', borderRadius: 0, padding: '20px 20px 40px 20px' },
        noUsers: { fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px', padding: '16px' },
        toolbar: { display: 'flex', alignItems: 'center' },
        btnText: { lineHeight: '1.75', fontWeight: 500, cursor: 'pointer', paddingLeft: '0.5rem', fontSize: '0.8125rem' },
        deleteBtnText: { color: theme.errorRed }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const notify = useNotify();
    const history = useHistory();

    const { id } = record;
    const [users, setUsers] = useState([]);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'users',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {
                    clientId: id
                }
            }
        },
        {
            onSuccess: ({ data, total }) => {
                setTotal(total);
                setUsers(data);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    const onEdit = (id) => {
        history.push(`/users/${id}`);
    };

    const AddUsersButton = (props) => {
        const theme = useSelector(state =>
            state.theme === "dark" ? darkTheme.palette : lightTheme.palette
        );

        const [open, setOpen] = useState(false);

        const handleClickOpen = (e) => {
            e.preventDefault();
            setOpen(true);
        };

        const handleClose = (value) => {
            setOpen(false);
        };


        return (
            <Fragment>
                <CustomButton
                    label="Add"
                    onClick={handleClickOpen}
                    style={{ marginBottom: '30px', color: theme.secondary.main, backgroundColor: 'white', border: `2px solid ${theme.secondary.main}` }}
                    fullWidth={true}
                    type="primary"
                />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={false}
                    classes={{ paper: classes.dialogPaper }}
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title" style={{ color: theme.secondary.main }}>Create New Client Administrator</DialogTitle>
                    <DialogContent>
                        <CreateAdministratorDialog refreshGrid={handleClose} clientId={id} />
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <AddUsersButton />
            {
                users.length ? (
                    <Fragment>
                        <CustomLabel title="Administrators" style={{ marginBottom: '22px', fontSize: '17px' }} />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        ID
                                    </TableCell>
                                    <TableCell>
                                        NAME
                                    </TableCell>
                                    <TableCell>
                                        EMAIL
                                    </TableCell>
                                    <TableCell>
                                        ROLE
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.name} {user.surname}</TableCell>
                                        <TableCell>{user.emailAddress}</TableCell>
                                        <TableCell>{user.isAdmin ? 'Administrator' : 'End User'}</TableCell>
                                        <TableCell>
                                            <div className={classes.toolbar}>
                                                <EditIcon  onClick={() => onEdit(user.id)} className={classes.editButton}/>
                                                <span onClick={() => onEdit(user.id)} className={classes.btnText} style={{ color: theme.primary.main }}>Edit</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.toolbar}>
                                                <DeleteConfirmationDialog record={user} clientId={id} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Pagination
                            page={page}
                            perPage={perPage}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            total={total}
                        />
                    </Fragment>
                ) : <p className={classes.noUsers}>No Administrators added</p>
            }
        </Fragment>
    );
};

export default ClientsUsersTab;