import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { TextInput, SelectInput, DateInput, email, required } from "react-admin";
import { darkTheme, lightTheme } from "../../layout/themes";

import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormLabel from '@material-ui/core/FormLabel';

export default function UserCreateDetailsTab(props) {
    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const styles = {
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: labelColor },
        flexContainer: { width: '100%' },
        select: { maxWidth: '29%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
        addressesSection: { marginTop: 55, marginBottom: 40, width: '100%' },
        addAddressBtn: { marginTop: 18, marginLeft: 20, padding: "5px 30px", textTransform: 'capitalize' }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const accessLevels = [
        { id: 0, name: 'Read Access' },
        { id: 1, name: 'Edit Access' },
        { id: 2, name: 'Full Access' }
    ];

    const userRoll = [
        { id: 1, name: 'Administrator'},
        { id: 4, name: 'Support'}
    ];

    const validateEmail = [email(), required()];

    return (
        <Fragment>
            <FormLabel component="legend" className={classes.formLabel}>Details</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="user.userName"
                        label="Username"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                    />
                    <TextInput
                        source="user.emailAddress"
                        label="Email Address"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={validateEmail}
                    />
                    <TextInput
                        source="user.phoneNumber"
                        label="Phone Number"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                    />
                </div>
                <div className={classes.row}>
                    <TextInput
                        source="user.name"
                        label="Name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                    />
                    <SelectInput
                        source="user.gender"
                        label="Gender"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        choices={[
                            { id: 0, name: 'Male' },
                            { id: 1, name: 'Female' }
                        ]}
                    />
                    <TextInput
                        source="user.middlename"
                        label="Middle name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                    />
                </div>
                <div className={classes.row}>
                    <TextInput
                        source="user.surname"
                        label="Last Name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateInput
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date of birth"
                            variant="standard"
                            fullWidth={true}
                            source="user.dateOfBirth"
                            // format="dd/MM/yyyy"
                            // onChange={handleDateChange}
                            className={classes.select}
                        />
                    </MuiPickersUtilsProvider>
                    <SelectInput
                        source="user.languageId"
                        label="Language"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        choices={[
                            { id: '1', name: 'English' },
                            { id: '2', name: 'Norwegian' }
                        ]}
                        defaultValue={"1"}
                    />
                </div>
                <div className={classes.row}>
                    <SelectInput
                        source="user.roleId"
                        choices={userRoll}
                        defaultValue={"4"}
                        variant="standard"
                        className={classes.select}
                        fullWidth={true}
                    />
                    <SelectInput
                        source="user.accessLevel"
                        choices={accessLevels}
                        defaultValue={"2"}
                        variant="standard"
                        className={classes.select}
                        fullWidth={true}
                        InputProps={{
                            readOnly: false
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};
